import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import Toast from "react-bootstrap/Toast";

export default function ToastComp({ messageTitle, message, toast, userName }) {
  return (
    <div className={[toast ? "loading" : ""]}>
      <Toast className={toast ? "visibleToast" : ""}>
        <Toast.Header className={messageTitle.includes("error") ? "error" : ""}>
          <strong className="mr-auto">{messageTitle}</strong>
        </Toast.Header>
        <Toast.Body>
          {userName && (<div className="p-3 mb-2 bg-secondary text-white">{userName}</div>)}
          {message}
        </Toast.Body>
      </Toast>
    </div>
  );
}
