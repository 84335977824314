import React, { useRef, useEffect, useState } from "react";
import "./../App.css";
import QrScanner from "qr-scanner";
import { validateTicket } from "../api";
import { getCookie } from "../Utils";
import { useNavigate } from "react-router-dom";
import { eventInformation } from "./../api.js";

function ValidationQR({
  setEvent,
  setMessageTitle,
  setMessage,
  setToast,
  setUserName,
}) {
  const videoRef = useRef(null);
  const navigate = useNavigate();

  const lastTicketRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  const getEventData = async () => {
    const hash = getCookie("eventHash");
    if (!hash) {
      return navigate("/");
    }
    const data = await eventInformation(hash);
    setEvent(data);
  };

  const resultScanning = async (result) => {
    if (result.data && result.data !== lastTicketRef.current) {
      setIsLoading(true);
      lastTicketRef.current = result.data;
      const response = await validateTicket(
        getCookie("eventHash"),
        result.data
      );
      if (response.success) {
        setMessageTitle("Se ha validado la entrada");
        setUserName(
          `${response.userName}`
        );
        setMessage(`Tipo de entrada: ${response.ticketType}. Validada ${response.validatedTicketsInPurchase}
         de ${response.totalTicketsInPurchase}`);
        getEventData();
        setToast(true);
        setTimeout(() => setToast(false), 4000);
      } else {
        setMessageTitle("Ha habido un error");
        setUserName("");
        setMessage(response.errorMessage);
        setToast(true);
        setTimeout(() => setToast(false), 3000);
      }

      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!getCookie("eventHash")) {
      return navigate("/");
    }
    const qrScanner = new QrScanner(
      videoRef.current,
      (result) => resultScanning(result),
      {
        highlightScanRegion: true,
        highlightCodeOutline: true,
        returnDetailedScanResult: false,
      }
    );
    qrScanner.start();

    return () => {
      qrScanner.destroy();
    };
  }, []);

  return (
    <div className="videocontainer">
      {isLoading && (
        <div className="loading">
          <div className="spinner"></div>
        </div>
      )}
      <div className="d-flex flex-column" style={{ height: "45vh" }}>
        <video ref={videoRef} width={"100%"} height={"100%"}></video>
      </div>
    </div>
  );
}

export default ValidationQR;
