import React, { useEffect, useState } from "react";
import "./../App.css";
import { Button, Form } from "react-bootstrap";
import { validateTicket } from "../api";
import { getCookie } from "../Utils";
import { useNavigate } from "react-router-dom";
import { eventInformation } from "../api.js";

function ValidationQR({
  setEvent,
  setMessageTitle,
  setMessage,
  setToast,
  setUserName,
}) {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [id, setId] = useState("");

  const getEventData = async () => {
    const hash = getCookie("eventHash");
    if (!hash) {
      return navigate("/");
    }
    const data = await eventInformation(hash);
    setEvent(data);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    console.log(id);
    const response = await validateTicket(
      getCookie("eventHash"),
      id.toString()
    );
    console.log(response);
    if (response.success) {
      setMessageTitle("Se ha validado la entrada");
      setUserName(
        response.userName
      );
      setMessage(`Tipo de entrada: ${response.ticketType}`);
      getEventData();
      setToast(true);
      setTimeout(() => setToast(false), 3000);
    } else {
      setMessageTitle("Ha habido un error");
      setUserName("");
      setMessage(
        response.errorMessage
          ? response.errorMessage
          : "Parece que el localizador no es correcto"
      );
      setToast(true);
      setTimeout(() => setToast(false), 3000);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (!getCookie("eventHash")) {
      return navigate("/");
    }
  }, []);

  return (
    <div className="box">
      <Form onSubmit={handleSubmit} className="formcontainer">
        <Form.Group className="inputcontainer">
          <Form.Control
            type="number"
            placeholder="Localizador (número debajo QR)"
            value={id}
            onChange={(event) => {
              setId(event.target.value);
            }}
          />
        </Form.Group>
        <Button variant="primary" type="submit" className="submitcontainer">
          Validar
        </Button>
      </Form>
    </div>
  );
}

export default ValidationQR;
